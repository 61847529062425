<template>
    <t-input
        v-model="value"
        :label="column.title"
        :error="error"
        @input="change"
    />
</template>

<script>
export default {
    name: 'TextInput',
    props: {
        error: {
            type: String,
            required: false,
        },
        column: {
            type: Object,
            required: true,
        },
        model: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            value: this.model.name,
        }
    },
    methods: {
        change(option) {
            this.$emit('changed', {
                updatedField: this.column.key,
                model: this.model,
                value: option,
            });
        },
    },
};
</script>

